<template>
  <form class="form-cms mb-2">
    <div class="form-control">
      <label for="name-ingredient">Nombre de la ingrediente:</label>
      <input
        type="text"
        name="name-ingredient"
        placeholder="Nombre de ingrediente"
      />
    </div>
    <div class="fields-flex">
      <div class="form-control">
        <label for="portion-ingredient">Porciones:</label>
        <input type="number" name="portion-ingredient" placeholder="Porción" />
      </div>
      <div class="form-control">
        <label for="family-ingredient">Familia:</label>
        <input
          type="text"
          name="family-ingredient"
          placeholder="Nombre de familia"
        />
      </div>
    </div>
    <div class="fields-flex">
      <div class="text-center">
        <div class="image-item">
          <img
            src="/images/emptys/empty-product.svg"
            alt="Ingrediente sin imagen"
          />
        </div>
        <button type="button" class="btn btn-secondary btn-medium mt-1">
          <i class="fas fa-upload"></i>
          Subir imagen
        </button>
      </div>
      <div class="">
        <div class="form-control">
          <label for="price-ingredient">Precio:</label>
          <div class="d-flex gap-0-5">
            <div class="price-text">
              <p>S/.</p>
            </div>
            <input type="number" name="price-ingredient" placeholder="Precio" />
          </div>
        </div>
        <div class="form-control">
          <label for="ingredient-x">Por:</label>
          <select name="ingredient-x">
            <option value="">Seleccionar</option>
            <option value="100gr">100gr</option>
            <option value="unit">Unidad</option>
            <option value="100ml">100ml</option>
          </select>
        </div>

        <div class="form-control">
          <label for="ingredient-duration">Duración:</label>
          <select name="ingredient-duration">
            <option value="">Seleccionar</option>
            <option value="unico-uso">Único uso</option>
            <option value="multiuso">Multiuso</option>
          </select>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button class="btn btn-primary">
        <i class="fas fa-pen-alt"></i> Actualizar
      </button>
    </div>
  </form>
</template>
